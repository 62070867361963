<template>
   <div
      class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--flat-light">
      <div class="ph-inpage-widget__content ph-inpage-widget-max-310">
        <div class="ph-inpage-widget__content-in">
          <div class="ph-inpage-widget__row ph-inpage-widget__row_center">
            <div class="ph-inpage-widget__thumb ph-inpage-widget__thumb-rounded-4">
              <img v-show="!imgIcon" :src="require('@/assets/panel/images/inpage/empty.svg')" alt="">
              <img :src="imgIcon" v-show="imgIcon" alt="">
            </div>
            <div class="ph-inpage-widget__txt">
              <div class="ph-inpage-widget__title ph-inpage-widget__txt-overflow-2">{{ adTitle }}</div>
              <div class="ph-inpage-widget__desc ph-inpage-widget__txt-overflow-2">{{ adText }}</div>
            </div>
          </div>
          <div class="ph-inpage-widget__close ph-inpage-widget__close-border">&#10005;</div>
        </div>
        <div class="ph-inpage-widget__after"></div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>